import React from 'react';
import { Location } from 'react-router-dom';
import 'react-router-dom';

import { InitContext, InitCtx } from '../context/InitProvider';

import auth from '../Auth/Auth';
import { PATHS } from './paths';
import { StringParam, useQueryParam } from 'use-query-params';
import { ISTA_QUERY_PARAM } from '../constants/auth';
import { IS_ISTA_USER } from '../constants/localStorage';

type Props = {
  location: Location;
};

const Authenticate = ({ location }: Props) => {
  const { initializing, setAuthSuccess } = React.useContext<InitCtx>(InitContext);
  const [t] = useQueryParam('t', StringParam);

  React.useEffect(() => {
    if (location.pathname === PATHS.AUTH.CALLBACK) {
      return;
    }

    if (!auth.isAuthenticated()) {
      auth.login({
        signup: location.pathname === PATHS.AUTH.SIGN_UP,
        // Have a special query param in case of ISTA we want to support enterprise auth flow so that we display
        // a different ista login button in the ui-auth
        customQueryParams: t === 'ista' || localStorage.getItem(IS_ISTA_USER) === 'true' ? ISTA_QUERY_PARAM : null,
      });
      return;
    }

    if (initializing) {
      setAuthSuccess();
    }
  }, [location, initializing, setAuthSuccess]);
  return null;
};

export default Authenticate;
