import React, { FC } from 'react';
import { NexoyaFunnelStepMapping, NexoyaFunnelStepV2, NexoyaMeasurement } from '../../../../../../types';
import { usePortfolio } from '../../../../../../context/PortfolioProvider';
import {
  FunnelStepLabelButtonStyled,
  FunnelStepTitleContainerStyled,
  LabelsStyled,
  LabelTitleStyled,
} from '../../../Funnel/styles';
import { capitalizeWords } from '../../../../../../utils/string';
import SvgCheckCircle from '../../../../../../components/icons/CheckCircle';
import { nexyColors } from '../../../../../../theme';
import translate from '../../../../../../utils/translate';
import { cn } from '../../../../../../lib/utils';
import { MappingType } from '../../ContentMetricAssignment';

interface Props {
  funnelSteps: NexoyaFunnelStepV2[];
  getSelectedMeasurementForFunnelStepId: (funnelStepId: number) => Partial<NexoyaMeasurement>;
  assignedMetrics: (NexoyaFunnelStepMapping & { mappingType?: MappingType })[];
  translations: any;
}

export const AssignMetricFunnelStepLabel: FC<Props> = ({
  translations,
  funnelSteps,
  assignedMetrics,
  getSelectedMeasurementForFunnelStepId,
}) => {
  const {
    selectedFunnelStep: { selectedFunnelStep, setSelectedFunnelStep },
  } = usePortfolio();

  const getAssignedMetricBasedOnMappingType = (funnelStepId: number) => {
    const assignedMetric = assignedMetrics.find((metric) => metric.funnelStepId === funnelStepId);
    const assignedMetricId = getSelectedMeasurementForFunnelStepId(funnelStepId);
    const assignedMetricName = translate(translations, assignedMetricId?.name);

    switch (assignedMetric?.mappingType) {
      case 'assign_metric':
        return {
          assignedMetricId: assignedMetricId?.measurement_id,
          assignedMetricName,
          mappingTypeLabel: 'Assign metric',
          mappingType: assignedMetric?.mappingType,
        };
      case 'custom_conversions':
        return {
          assignedMetricId: assignedMetric?.metricId,
          assignedMetricName,
          mappingTypeLabel: 'Custom conversions',
          mappingType: assignedMetric?.mappingType,
        };
      case 'utm_tracking':
        return {
          assignedMetricId: null,
          assignedMetricName: 'UTM tracking',
          mappingTypeLabel: 'UTM tracking',
          mappingType: assignedMetric?.mappingType,
        };
      case 'ignore_mapping':
        return {
          assignedMetricId: null,
          assignedMetricName: 'No metric',
          mappingTypeLabel: 'Ignore mapping',
          mappingType: assignedMetric?.mappingType,
        };
      default:
        return {
          assignedMetricId: null,
          assignedMetricName: '',
          mappingTypeLabel: '',
          mappingType: null,
        };
    }
  };

  return (
    <LabelsStyled>
      {funnelSteps?.map((funnelStep) => {
        const funnelStepId = funnelStep?.funnelStepId;
        const funnelStepSelected = selectedFunnelStep?.funnel_step_id === funnelStepId;

        const { assignedMetricId, assignedMetricName, mappingType, mappingTypeLabel } =
          getAssignedMetricBasedOnMappingType(funnelStepId);
        const hasAssigned = mappingType === 'ignore_mapping' ? true : assignedMetricId != null;

        return (
          <FunnelStepLabelButtonStyled
            key={funnelStepId}
            active={funnelStepSelected || hasAssigned}
            className={funnelStepSelected ? 'bg-inherit' : hasAssigned ? '!bg-white' : ''}
            labelClassName={cn('h-full flex flex-col justify-between', hasAssigned && '!opacity-100')}
            onClick={() =>
              setSelectedFunnelStep({
                title: funnelStep?.title,
                funnel_step_id: funnelStep.funnelStepId,
                type: funnelStep.type,
              })
            }
          >
            <FunnelStepTitleContainerStyled style={{ marginBottom: 0 }}>
              <LabelTitleStyled className="flex items-center gap-2">
                {capitalizeWords(funnelStep.title)}{' '}
                {hasAssigned ? <SvgCheckCircle style={{ color: nexyColors.greenTeal, width: 14, height: 14 }} /> : null}
              </LabelTitleStyled>
            </FunnelStepTitleContainerStyled>
            <div className="text-sm font-light">
              {hasAssigned ? (
                <div className="text-neutral-800">
                  {assignedMetricName} <br />
                  <span className="text-xs text-neutral-400">Type: {mappingTypeLabel}</span>
                </div>
              ) : (
                <div className="text-neutral-300">
                  No assignment yet <br />
                </div>
              )}
            </div>
          </FunnelStepLabelButtonStyled>
        );
      })}
    </LabelsStyled>
  );
};
