import { gql, useMutation } from '@apollo/client';
import { toast } from 'sonner';
import { NexoyaContentFilterInput } from '../../types';
import { CONTENT_GROUP_QUERY } from './queryContentSets';
import { useTeam } from '../../context/TeamProvider';

const CREATE_CONTENT_GROUP_MUTATION = gql`
  mutation CreateContentSet(
    $filters: [ContentFilterInput!]!
    $name: String!
    $portfolioId: Float!
    $providerId: Float!
    $teamId: Float!
    $adAccountId: Float
  ) {
    createContentSet(
      filters: $filters
      name: $name
      portfolioId: $portfolioId
      providerId: $providerId
      teamId: $teamId
      adAccountId: $adAccountId
    ) {
      name
    }
  }
`;

type CreateContentSetProps = {
  name: string;
  teamId: number;
  portfolioId: number;
  providerId: number;
  adAccountId?: number;
  filters: NexoyaContentFilterInput[];
};

export function useCreateContentSetMutation({ portfolioId }) {
  const { teamId } = useTeam();

  return useMutation<
    { createContentSet: { id: number; name: string; portfolioId: number; providerId: number; teamId: number } },
    CreateContentSetProps
  >(CREATE_CONTENT_GROUP_MUTATION, {
    awaitRefetchQueries: true,
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
    onCompleted: (data) => {
      toast.success(`Content group ${data.createContentSet.name} created successfully`);
    },
    refetchQueries: [
      {
        notifyOnNetworkStatusChange: true,
        query: CONTENT_GROUP_QUERY,
        variables: {
          teamId,
          portfolioId,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });
}
