import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { ChevronsUpDown } from 'lucide-react';
import { useProviders } from '../../../../../../context/ProvidersProvider';
import translate from '../../../../../../utils/translate';
import { useTranslationsQuery } from '../../../../../../graphql/translation/queryTranslations';
import {
  NexoyaContentFilterFieldName,
  NexoyaContentFilterOperator,
  NexoyaContentV2,
  NexoyaProvider,
} from '../../../../../../types';
import AvatarProvider from '../../../../../../components/AvatarProvider';
import Button from '../../../../../../components/Button';
import { toNumber, truncate } from 'lodash';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '../../../../../../components-ui/DropdownMenu';
import ButtonAdornment from '../../../../../../components/ButtonAdornment';
import FormControlLabel from '../../../../../../components/FormControlLabel';
import Radio from '../../../../../../components/Radio';
import { useProviderSubAccountsQuery } from '../../../../../../graphql/contentSets/queryProviderSubAccounts';
import { useTeam } from '../../../../../../context/TeamProvider';
import Tooltip from '../../../../../../components/Tooltip';

interface Options {
  value: string;
  label: string;
  subOptions: { value: string; label: string }[];
}

interface Props {
  portfolioId: number;
  selectedAccountId: number;
  setSelectedAccountId: Dispatch<SetStateAction<number>>;
  selectedProviderId: number;
  setSelectedProviderId: Dispatch<SetStateAction<number>>;
  setShouldFetch: Dispatch<SetStateAction<boolean>>;
}

export function ProviderDropdownFilter({
  portfolioId,
  selectedProviderId,
  setSelectedProviderId,
  selectedAccountId,
  setSelectedAccountId,
  setShouldFetch,
}: Props) {
  const [subAccounts, setSubAccounts] = useState<NexoyaContentV2[]>([]);
  const { activeProviders } = useProviders();
  const { teamId } = useTeam();

  const { data: translationData } = useTranslationsQuery();
  const translations = translationData?.translations ?? [];

  const { loading } = useProviderSubAccountsQuery({
    teamId,
    portfolioId,
    filters: [
      {
        fieldName: NexoyaContentFilterFieldName.ContentType,
        operator: NexoyaContentFilterOperator.Eq,
        value: {
          number: 2,
        },
      },
    ],
    excludePortfolioContents: false,
    onCompleted: (data) => setSubAccounts(data?.filterContents),
  });

  const selectedProvider = activeProviders.find(
    (provider: NexoyaProvider) => provider.provider_id === selectedProviderId,
  );

  const providerOptions: Options[] = activeProviders
    ?.filter((p) => p.isPortfolioPrimaryChannel)
    ?.map((p: NexoyaProvider) => ({
      value: p.provider_id?.toString(),
      label: p.name,
      subOptions: subAccounts
        .filter((s) => s.provider?.provider_id === p.provider_id)
        .map((c) => ({
          value: c.contentId,
          label: translate(translations, c.title),
        })),
    }));

  const selectedSubAccountTitle = translate(
    translations,
    subAccounts.find((sb) => sb.contentId === selectedAccountId)?.title,
  );
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          loading={loading}
          disabled={loading || !providerOptions.length}
          size="small"
          variant="contained"
          color="secondary"
          style={{ minWidth: 200, display: 'flex', justifyContent: 'space-between', padding: '6px 12px' }}
          endAdornment={
            <ButtonAdornment position="end">
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </ButtonAdornment>
          }
        >
          {selectedProvider ? (
            <div className="flex items-center gap-3">
              <AvatarProvider variant="circle" providerId={selectedProvider?.provider_id} size={16} color="dark" />
              {translate(translations, selectedProvider?.name)}
              {selectedAccountId ? (
                <Tooltip
                  variant="dark"
                  placement="right"
                  content={selectedSubAccountTitle?.length > 20 ? selectedSubAccountTitle : ''}
                  popperProps={{
                    style: { zIndex: 3100 },
                  }}
                >
                  <span className="text-neutral-400">- {truncate(selectedSubAccountTitle, { length: 20 })}</span>
                </Tooltip>
              ) : null}
            </div>
          ) : (
            'Select a channel...'
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-60 font-normal" align="start">
        {providerOptions.map((provider: Options) => {
          // If the provider has no subOptions, render as a single DropdownMenuItem
          if (provider.subOptions.length === 0) {
            return (
              <DropdownMenuItem
                key={provider.value}
                onSelect={() => {
                  setSelectedProviderId(toNumber(provider.value));
                  setSelectedAccountId(null); // Reset account ID if no sub-options are available
                }}
              >
                <AvatarProvider className="mr-2" providerId={provider.value} size={15} color="dark" />
                <span>{translate(translations, provider.label)}</span>
              </DropdownMenuItem>
            );
          }

          // If the provider has subOptions, render as DropdownMenuSub
          return (
            <DropdownMenuSub key={provider.value}>
              <DropdownMenuSubTrigger>
                <AvatarProvider className="mr-2" providerId={provider.value} size={15} color="dark" />
                <span>{translate(translations, provider.label)}</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {provider.subOptions.map((sub) => (
                    <DropdownMenuItem
                      key={sub.value}
                      onSelect={(e) => {
                        e.preventDefault();
                        setSelectedProviderId(toNumber(provider.value));
                        setSelectedAccountId(toNumber(sub.value));
                        setShouldFetch(true);
                      }}
                    >
                      <FormControlLabel
                        key={sub.value}
                        checked={sub.value?.toString() === selectedAccountId?.toString()}
                        onChange={() => {
                          setSelectedProviderId(toNumber(provider.value));
                          setSelectedAccountId(toNumber(sub.value));
                        }}
                        value={sub.value}
                        label={sub.label}
                        control={
                          <Radio
                            inputProps={{
                              'aria-label': 'A',
                            }}
                          />
                        }
                      />
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
