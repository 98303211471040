import { VerticalStep } from '../components/VerticalStepper/Step';

export const contentSelectionSteps: VerticalStep[] = [
  {
    id: 'content-selection-details',
    name: 'Select contents',
    description: 'Select the contents you want to add to your portfolio.',
  },
  {
    id: 'content-assignment',
    name: 'Assign metrics',
    description: 'Assign metrics to your selected contents.',
  },
  {
    id: 'content-impact-groups',
    name: 'Impact groups',
    description: 'Assign impact groups to your selected contents.',
  },
];
