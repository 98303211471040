import React, { useEffect, useMemo, useState } from 'react';

import { get, uniq } from 'lodash';
import styled from 'styled-components';

import { NexoyaCollection, NexoyaPortfolioContentDetail, PortfolioCollectionContent } from 'types';

import { usePortfolio } from '../../context/PortfolioProvider';
import { usePortfolioQuery } from '../../graphql/portfolio/queryPortfolio';
import GridWrap from '../../components/GridWrap';
import SvgKpi from '../../components/icons/Kpi';
import ContentEdit from './components/Content/ContentEdit';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import NoDataFound from './NoDataFound';
import { ExtendedTable } from '../../components/Table/ExtendedTable';
import { useTranslationsQuery } from '../../graphql/translation/queryTranslations';
import { TableStyled } from './styles/OptimizationProposal';
import { TableManager } from '../../components/Table/TableManager';
import { getColumns } from './components/Content/columns';
import { PerformanceHeader } from './components/PerformanceChartHeader';
import Checkbox from '../../components/Checkbox';
import { getData } from './components/Content/data-table';
import TextField from '../../components/TextField';
import { useLabels } from 'context/LabelsProvider';
import { ACTIONS_HEADER_ID } from './components/OptimizationProposal/columns';
import useTeamColor from '../../hooks/useTeamColor';
import PortfolioFeatureSwitch from '../../components/PortfolioFeatureSwitch';
import { PORTFOLIO_FEATURE_FLAGS } from '../../constants/featureFlags';
import ContentEditV2 from './components/Content/ContentEditV2';
import { useImpactGroups } from '../../context/ImpactGroupsProvider';
import { useSidePanelState } from '../../components/SidePanel';

type Props = {
  dateFrom: Date;
  dateTo: Date | string;
  portfolioId: number;
};
const GridWrapStyled = styled(GridWrap)`
  .NEXYGridHeader,
  .NEXYCSSGrid {
    padding: 0 24px;
  }
`;
const ContentEditWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  position: absolute;
  top: 203px;
  right: 0;
`;

export const LoadingWrapStyled = styled.div`
  padding-top: 20px;
  height: 100vh;

  & > div {
    margin-bottom: 15px;
    background: #f4f6f7;
    height: 40px;

    &:nth-child(1) {
      opacity: 1;
    }
    &:nth-child(2) {
      opacity: 0.75;
    }
    &:nth-child(3) {
      opacity: 0.5;
    }
    &:nth-child(4) {
      opacity: 0.25;
    }
  }
`;

function Content({ dateFrom, dateTo, portfolioId }: Props) {
  const [isMounted, setIsMounted] = useState(false);
  const [hideDisabledContents, setHideDisabledContents] = useState(false);
  const [search, setSearch] = useState('');

  const { isOpen, toggleSidePanel } = useSidePanelState();

  const [mappedContentIds, setMappedContentIds] = useState<PortfolioCollectionContent[]>([]);
  const getThemeColor = useTeamColor();
  const { contentSelection } = usePortfolio();
  const {
    portfolioInfo: { data: portfolio, loading, updateState: updateLegacyPortfolio },
    portfolioV2Info: {
      meta: { data: portfolioMeta },
    },
  } = usePortfolio();
  const { data: translationData } = useTranslationsQuery();
  const translations = translationData?.translations || [];

  const {
    providers: { providersFilter },
  } = usePortfolio();

  const {
    filter: { labelsFilter },
  } = useLabels();

  const {
    filter: { impactGroupsFilter },
  } = useImpactGroups();

  const {
    data: legacyPortfolio,
    loading: legacyPortfolioLoading,
    error: legacyPortfolioError,
    refetch: refetchLegacyPortfolio,
  } = usePortfolioQuery({
    portfolioId,
    dateFrom,
    dateTo,
    withBudget: false,
  });

  useEffect(() => {
    updateLegacyPortfolio({
      data: legacyPortfolio?.portfolio,
      loading: legacyPortfolioLoading,
      error: legacyPortfolioError,
    });
  }, [legacyPortfolio, legacyPortfolioLoading, legacyPortfolioError]);

  const funnelSteps = portfolio?.funnelSteps;
  const content: NexoyaPortfolioContentDetail[] | NexoyaCollection[] = useMemo(
    () => get(portfolio, 'content.contentDetails', []) || [],
    [portfolio],
  );
  // repact collection info so it matches the schema returned from
  // collections query
  useEffect(() => {
    contentSelection.reset();
    const preselectedContent = [];
    content.forEach((item) => {
      preselectedContent.push({
        collection_id: item.contentId,
        title: get(item, 'content.title'),
        provider: get(item, 'content.provider'),
        collectionType: item.content?.collectionType,
        portfolioContentId: item.portfolioContentId,
        parent: true,
        childContent: item.childContent
          ? item.childContent.map((childItem) => ({
              collection_id: childItem.contentId,
              title: get(childItem, 'content.title'),
              portfolioContentId: item.portfolioContentId,
              parent: false,
            }))
          : null,
      });
      setMappedContentIds((s) => [
        ...s,
        {
          collection_id: item.contentId,
          portfolio_content_id: item.portfolioContentId,
        },
      ]);
    });
    contentSelection.add(preselectedContent);
    contentSelection.setInitial(preselectedContent);
  }, [portfolio?.content, contentSelection.reset]);

  useEffect(() => {
    setTimeout(() => setIsMounted(true));
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault();
        document.getElementById('content-search')?.focus();
      }
    };

    if (!isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const hasLabels = portfolioMeta?.labels?.length > 0;
  const hasBidStrategy = content.some((c) => c?.metadata?.biddingStrategy);
  const hasBudgetLimits = content?.some((c) => c?.budget?.min || c?.budget?.max);

  if (loading) {
    return (
      <LoadingWrapStyled>
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
      </LoadingWrapStyled>
    );
  }

  if (!content.length) {
    return (
      <>
        <ContentEditWrapperStyled>
          <PortfolioFeatureSwitch
            features={[PORTFOLIO_FEATURE_FLAGS.CONTENT_EDIT_V2]}
            renderNew={() => {
              return (
                <ContentEditV2
                  toggleSidePanel={toggleSidePanel}
                  isOpen={isOpen}
                  refetchPortfolio={refetchLegacyPortfolio}
                  dateTo={dateTo}
                  dateFrom={dateFrom}
                />
              );
            }}
            renderOld={() => (
              <ContentEdit
                refetchPortfolio={refetchLegacyPortfolio}
                dateTo={dateTo}
                dateFrom={dateFrom}
                mappedContentIds={mappedContentIds}
              />
            )}
          />
        </ContentEditWrapperStyled>
        <NoDataFound
          icon={<SvgKpi />}
          title="There are no contents assigned in this portfolio."
          subtitle="Please select the 'Edit content' button to add contents. Once you do, you'll see them here."
        />
      </>
    );
  }

  if (loading || !isMounted) {
    return (
      <LoadingWrapStyled>
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
      </LoadingWrapStyled>
    );
  }

  const filteredProviderIds = providersFilter.map((provider) => provider.provider_id);
  const filteredLabelIds = labelsFilter.map((label) => label.labelId);
  const filteredImpactGroupIds = impactGroupsFilter.map((impactGroup) => impactGroup.impactGroupId);
  const activeProviderIds = uniq(content.map((item) => item.content.provider.provider_id));

  return (
    <>
      <PerformanceHeader
        disabled={false}
        shouldRenderCustomization={false}
        shouldRenderLabelsFilter
        shouldRenderImpactGroupsFilter
        activeProviderIds={activeProviderIds}
        renderAdditionalComponents={() => (
          <TextField
            style={{ width: 304 }}
            wrapProps={{ style: { padding: '6px 12px' } }}
            placeholder="Search contents..."
            value={search}
            name="search"
            id="content-search"
            labelVariant="light"
            onChange={(e) => setSearch(e?.target?.value)}
          />
        )}
        renderSwitcher={() => (
          <Checkbox
            checked={hideDisabledContents}
            onChange={(_: Event, value: boolean) => setHideDisabledContents(value)}
            label="Show disabled contents"
          />
        )}
      />

      <ContentEditWrapperStyled>
        <PortfolioFeatureSwitch
          features={[PORTFOLIO_FEATURE_FLAGS.CONTENT_EDIT_V2]}
          renderNew={() => {
            return (
              <ContentEditV2
                isOpen={isOpen}
                toggleSidePanel={toggleSidePanel}
                refetchPortfolio={refetchLegacyPortfolio}
                dateTo={dateTo}
                dateFrom={dateFrom}
              />
            );
          }}
          renderOld={() => (
            <ContentEdit
              refetchPortfolio={refetchLegacyPortfolio}
              dateTo={dateTo}
              dateFrom={dateFrom}
              mappedContentIds={mappedContentIds}
            />
          )}
        />
      </ContentEditWrapperStyled>
      {isMounted && !loading ? (
        <TableStyled maxHeight="90vh">
          <ExtendedTable
            tableId="content_table"
            disableManager={false}
            disableExpanded={false}
            disablePagination={false}
            data={getData({
              funnelSteps,
              content,
              filteredProviderIds,
              filteredLabelIds,
              filteredImpactGroupIds,
              hideDisabledContents,
              search,
              dateTo,
              dateFrom,
              translations,
              portfolio,
              getThemeColor,
            })}
            columns={getColumns({ funnelSteps, hasLabels, hasBidStrategy, hasBudgetLimits })}
            renderTableManager={({
              columns,
              getToggleHideAllColumnsProps,
              toggleHideAllColumns,
              setStickyColumns,
              stickyColumns,
            }) => (
              <TableManager
                idsNotAllowedToHide={[ACTIONS_HEADER_ID, 'expander', 'content']}
                columns={columns}
                getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                toggleHideAllColumns={toggleHideAllColumns}
                setStickyColumns={setStickyColumns}
                stickyColumns={stickyColumns}
                depth={1}
              />
            )}
          />
        </TableStyled>
      ) : null}
    </>
  );
}

export default Content;
