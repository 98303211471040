import { gql, QueryResult, useQuery } from '@apollo/client';
import { useTeam } from '../../context/TeamProvider';
import { DAILY_METRICS_FUNNEL_STEP_FRAGMENT } from './fragments';
import { NexoyaFunnelStepPerformance } from '../../types';

type DateRangeInput = {
  start: string;
  end: string;
};

const PERFORMANCE_FUNNEL_STEP_QUERY = gql`
  query FunnelStepPerformance(
    $teamId: Int!
    $portfolioId: Int!
    $period: DateRangeInput!
    $comparisonPeriod: DateRangeInput
    $funnelStepId: Float!
  ) {
    portfolioV2(teamId: $teamId, portfolioId: $portfolioId) {
      performance(period: $period, comparisonPeriod: $comparisonPeriod) {
        funnelStep(funnelStepId: $funnelStepId) {
          ...DailyFunnelStepFragment
        }
      }
    }
  }
  ${DAILY_METRICS_FUNNEL_STEP_FRAGMENT}
`;

type FunnelStepQueryVariables = {
  teamId: number;
  portfolioId: number;
  period: DateRangeInput;
  comparisonPeriod?: DateRangeInput;
  funnelStepId: number;
};

type FunnelStepQueryResult = {
  portfolioV2: {
    performance: {
      funnelStep: NexoyaFunnelStepPerformance;
    };
  };
};

type UseFunnelStepQueryOptions = {
  portfolioId: number;
  period: DateRangeInput;
  comparisonPeriod?: DateRangeInput;
  funnelStepId: number;
  skip?: boolean;
};

type UseFunnelStepQueryType = (
  options: UseFunnelStepQueryOptions,
) => QueryResult<FunnelStepQueryResult, FunnelStepQueryVariables>;

const usePerformanceFunnelStepQuery: UseFunnelStepQueryType = ({
  portfolioId,
  period,
  comparisonPeriod,
  funnelStepId,
  skip,
}) => {
  const { teamId } = useTeam();

  return useQuery<FunnelStepQueryResult, FunnelStepQueryVariables>(PERFORMANCE_FUNNEL_STEP_QUERY, {
    variables: {
      teamId: teamId!,
      portfolioId,
      period,
      comparisonPeriod,
      funnelStepId,
    },
    skip: skip ?? (!teamId || !portfolioId || !funnelStepId),
    fetchPolicy: 'cache-first',
  });
};

export { usePerformanceFunnelStepQuery, PERFORMANCE_FUNNEL_STEP_QUERY };
