import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../../components-ui/AlertDialog';
import ButtonAsync from '../../ButtonAsync';
import React, { useState } from 'react';
import TextField from '../../TextField';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components-ui/DropdownMenu';
import { ButtonStyled } from '../../../routes/portfolio/components/PerformanceChartHeader/PerformanceChartHeader.styles';
import { NexoyaContentSet } from '../../../types';
import FormControlLabel from '../../FormControlLabel';
import Radio from '../../Radio';
import RadioGroup from '../../RadioGroup';
import { toast } from 'sonner';

const OPTIONS = [
  { label: 'Save', value: 'save' },
  { label: 'Update', value: 'update' },
];

interface Props {
  closeDialog: () => void;
  open: boolean;
  loading: boolean;
  handleSave: (name: string) => void;
  handleUpdate: (contentSetId: number) => void;
  contentSets: NexoyaContentSet[];
}

export const SaveFilterDialog = ({ closeDialog, open, loading, handleSave, contentSets, handleUpdate }: Props) => {
  const [name, setName] = useState('');
  const [selectedContentSetId, setSelectedContentSetId] = useState(null);
  const [selectedOption, setSelectedOption] = useState('save');

  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Save or update the filter</AlertDialogTitle>
          <AlertDialogDescription>
            <span className="mt-1 text-sm font-normal leading-5 text-neutral-400">
              You will be able to load this filter after saving it
            </span>
          </AlertDialogDescription>
        </AlertDialogHeader>

        <div className="mt-[-16px]">
          <RadioGroup className="ml-[-3px] mt-4 flex flex-col gap-1.5">
            {OPTIONS.map((option) => (
              <div key={option.value} className="flex items-center gap-2">
                <FormControlLabel
                  key={option.value}
                  checked={option.value === selectedOption}
                  onChange={() => setSelectedOption(option.value)}
                  value={option.value}
                  label={option.label}
                  control={
                    <Radio
                      inputProps={{
                        'aria-label': 'A',
                      }}
                    />
                  }
                  data-cy={option.value}
                />
              </div>
            ))}
          </RadioGroup>
          {selectedOption === 'save' ? (
            <div className="mt-6">
              <AlertDialogDescription>
                <span className="text-sm font-normal leading-5 text-neutral-400">
                  You can choose to save this as a new filter with the current filter selection.
                </span>
              </AlertDialogDescription>
              <TextField
                placeholder="Type filter name..."
                className="my-2 !w-full"
                value={name}
                name="name"
                id="content-filter-name"
                labelVariant="light"
                onChange={(e) => setName(e?.target?.value)}
              />
            </div>
          ) : (
            <div className="my-2.5 mt-6 flex flex-col gap-3">
              <AlertDialogDescription>
                <span className="text-sm font-normal leading-5 text-neutral-400">
                  You can choose to update a filter with the current filter selection instead of saving a separate one.
                </span>
              </AlertDialogDescription>
              <DropdownMenu>
                <DropdownMenuTrigger disabled={!contentSets?.length} asChild>
                  <ButtonStyled className="!py-3" variant="contained" color="secondary" data-cy="filterSourceBtn">
                    <span className="whitespace-pre" style={{ marginLeft: 5 }}>
                      {selectedContentSetId
                        ? contentSets.find((c) => c.contentSetId === selectedContentSetId)?.name
                        : 'Select a content group id'}
                    </span>
                  </ButtonStyled>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-fit font-normal" align="end">
                  {contentSets?.map((contentSet) => (
                    <DropdownMenuItem
                      key={contentSet?.contentSetId}
                      onSelect={() => setSelectedContentSetId(contentSet.contentSetId)}
                    >
                      {contentSet.name}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}
        </div>
        <AlertDialogFooter>
          <AlertDialogAction>
            <ButtonAsync
              onClick={() => {
                closeDialog();
                setName('');
              }}
              disabled={loading}
              loading={loading}
              variant="contained"
              color="secondary"
              size="small"
            >
              Cancel
            </ButtonAsync>
          </AlertDialogAction>

          <AlertDialogAction>
            <ButtonAsync
              onClick={() => {
                if (selectedOption === 'save') {
                  const isDuplicateName = contentSets.some((set) => set.name.toLowerCase() === name.toLowerCase());

                  if (isDuplicateName) {
                    toast.error('A content set with this name already exists, try inserting a different name.');
                    return;
                  }

                  handleSave(name);
                  setName('');
                } else {
                  handleUpdate(selectedContentSetId);
                  setName('');
                  setSelectedContentSetId(null);
                }
              }}
              disabled={loading || (selectedOption === 'save' ? !name : !selectedContentSetId)}
              loading={loading}
              variant="contained"
              color="primary"
              size="small"
            >
              {selectedOption === 'save' ? 'Save' : 'Update'}
            </ButtonAsync>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
