import React, { useEffect, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client/core/types';

import { usePortfolio } from '../../../../context/PortfolioProvider';

import { track } from '../../../../constants/datadog';
import { EVENT } from '../../../../constants/events';

import Button from '../../../../components/Button';
import ButtonAdornment from '../../../../components/ButtonAdornment';
import ButtonAsync from '../../../../components/ButtonAsync';
import SidePanel, { SidePanelActions } from '../../../../components/SidePanel';
import SvgPencil from '../../../../components/icons/Pencil';
import VerticalStepper from '../../../../components/VerticalStepper';
import { useStepper } from '../../../../components/Stepper';
import { contentSelectionSteps } from '../../../../configs/content';
import { ContentMetricAssignment, MappingType } from './ContentMetricAssignment';
import { useUpsertPortfolioContentConfigMutation } from '../../../../graphql/portfolio/mutationUpsertPortfolioContentConfig';
import { useFunnelStepsV2Query } from '../../../../graphql/portfolio/queryFunnelSteps';
import ContentSelectionV2 from '../../../../components/ContentSelection/ContentSelectionV2';
import ErrorMessage from '../../../../components/ErrorMessage';
import { ContentImpactGroupAssignment } from './ContentImpactGroupAssignment';
import { useTeam } from 'context/TeamProvider';
import { toast } from 'sonner';
import { NexoyaContentFilter, NexoyaFunnelStepMapping } from '../../../../types';
import { DataTableFilterOption } from './ContentSet/types';

type Props = {
  dateFrom: Date;
  dateTo: Date | string;
  refetchPortfolio: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>;
  toggleSidePanel: () => void;
  isOpen: boolean;
};

const NUM_OF_STEPS = contentSelectionSteps.length;

function ContentEditV2({ dateFrom, dateTo, refetchPortfolio, toggleSidePanel, isOpen }: Props) {
  const [selectedContentIds, setSelectedContentIds] = useState<number[]>([]);
  const [selectedProviderId, setSelectedProviderId] = useState<number | null>(null);
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null);

  const [selectedOptions, setSelectedOptions] = useState<DataTableFilterOption[]>([]);
  const [pendingFilters, setPendingFilters] = useState<NexoyaContentFilter[]>([]);

  const [assignedMetrics, setAssignedMetrics] = useState<(NexoyaFunnelStepMapping & { mappingType?: MappingType })[]>(
    [],
  );
  const [selectedImpactGroupId, setSelectedImpactGroupId] = useState<number | null>(null);

  const { teamId } = useTeam();
  const {
    portfolioInfo: { data: portfolio },
    selectedFunnelStep: { setSelectedFunnelStep },
    portfolioV2Info: {
      meta: { data: portfolioMeta },
    },
  } = usePortfolio();

  const impactGroups = portfolioMeta?.impactGroups || [];
  const moreThanOneImpactGroup = impactGroups?.length > 1;

  const filteredContentSelectionSteps = moreThanOneImpactGroup
    ? contentSelectionSteps
    : contentSelectionSteps.filter((step) => step.id !== 'content-impact-groups');

  const { step, nextStep, previousStep, resetStep } = useStepper({
    initialValue: 1,
    end: moreThanOneImpactGroup ? NUM_OF_STEPS : NUM_OF_STEPS - 1,
  });

  const [
    upsertPortfolioContentConfig,
    { loading: upsertPortfolioContentConfigLoading, error: upsertPortfolioContentConfigError },
  ] = useUpsertPortfolioContentConfigMutation();

  const { data: funnelStepsData } = useFunnelStepsV2Query({
    portfolioId: portfolio?.portfolioId,
    onCompleted: (data) => {
      if (data?.portfolioV2?.funnelSteps?.length) {
        setSelectedFunnelStep({
          title: data.portfolioV2.funnelSteps[0]?.title,
          funnel_step_id: data.portfolioV2.funnelSteps[0].funnelStepId,
          type: data.portfolioV2.funnelSteps[0].type,
        });
      }
    },
  });

  useEffect(() => {
    if (impactGroups?.length === 1) {
      setSelectedImpactGroupId(impactGroups[0]?.impactGroupId);
    }
  }, [impactGroups]);

  const handleSubmit = async () => {
    try {
      await upsertPortfolioContentConfig({
        variables: {
          portfolioId: portfolio?.portfolioId,
          teamId,
          impactGroupId: selectedImpactGroupId,
          contentIds: selectedContentIds,
          funnelStepMappings: assignedMetrics.map(({ funnelStepId, metricId, conversionExternalIdentifierHash }) => ({
            funnelStepId: Number(funnelStepId),
            metricId,
            conversionExternalIdentifierHash,
          })),
        },
      });

      toast.success('Content added successfully');
      await refetchPortfolio({ dateTo, dateFrom, withBudget: true, withOptimization: false });
      track(EVENT.CONTENT_EDIT_V2, {
        selectedProviderId,
        selectedImpactGroupId,
        selectedContentIdsNumber: selectedContentIds.length,
      });

      toggleSidePanel();
      resetStep();
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      toast.error(err.message);
    }
  };

  const closeSidepanel = () => {
    toggleSidePanel();
    setSelectedProviderId(null);
    setSelectedAccountId(null);
    setSelectedImpactGroupId(null);
    setSelectedContentIds([]);
    setAssignedMetrics([]);
    setSelectedOptions([]);
  };

  const getNextFunnelStep = () =>
    funnelStepsData?.portfolioV2?.funnelSteps.find((funnelStep) => {
      const assignedMetric = assignedMetrics.find((metric) => metric.funnelStepId === funnelStep.funnelStepId);
      return !assignedMetric || !assignedMetric.mappingType;
    });

  const disableNextStep = () => {
    if (upsertPortfolioContentConfigLoading) return true;

    if (step === 1) return !selectedContentIds.length;

    if (step === 2) {
      return assignedMetrics.some((metric) => metric.mappingType !== 'ignore_mapping' && !metric.metricId);
    }

    if (moreThanOneImpactGroup && step === 3) return !selectedImpactGroupId;

    return false;
  };

  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <ContentSelectionV2
            setSelectedProviderId={setSelectedProviderId}
            selectedProviderId={selectedProviderId}
            setSelectedContentIds={setSelectedContentIds}
            selectedContentIds={selectedContentIds}
            selectedAccountId={selectedAccountId}
            setSelectedAccountId={setSelectedAccountId}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            pendingFilters={pendingFilters}
            setPendingFilters={setPendingFilters}
          />
        );
      case 2:
        return (
          <ContentMetricAssignment
            funnelSteps={funnelStepsData?.portfolioV2?.funnelSteps}
            accountId={selectedAccountId}
            assignedMetrics={assignedMetrics}
            setAssignedMetrics={setAssignedMetrics}
            providerId={selectedProviderId}
          />
        );
      case 3:
        return (
          <ContentImpactGroupAssignment
            impactGroups={impactGroups}
            selectedContentIds={selectedContentIds}
            selectedImpactGroupId={selectedImpactGroupId}
            setSelectedImpactGroupId={setSelectedImpactGroupId}
          />
        );
    }
  };

  return (
    <>
      <Button
        variant="text"
        color="tertiary"
        id="editContent"
        onClick={() => {
          toggleSidePanel();
          track(EVENT.CONTENT_EDIT_DIALOG);
        }}
        startAdornment={
          <ButtonAdornment position="start">
            <SvgPencil />
          </ButtonAdornment>
        }
      >
        Edit content
      </Button>
      <SidePanel
        isOpen={isOpen}
        onClose={closeSidepanel}
        paperProps={{ style: { width: 'calc(100% - 218px)', paddingBottom: '78px' } }}
      >
        <div className="border border-b-[#eaeaea] px-6 py-5">
          <h3 className="text-xl font-medium text-neutral-900">Select contents to add</h3>
        </div>
        <div className="grid h-screen grid-cols-[17rem_minmax(0,_1fr)] p-6">
          <div className="row-span-2 h-full w-[17rem]">
            <VerticalStepper
              current={step}
              steps={filteredContentSelectionSteps}
              className="!relative w-[17rem] !pr-0"
            />
          </div>
          <div className="row-span-2 w-full">{renderSteps()}</div>
        </div>
        <SidePanelActions className="!fixed bottom-0 z-[3400] !w-[calc(100%-218px)] border-t border-neutral-100">
          {step > 1 && (
            <Button
              disabled={upsertPortfolioContentConfigLoading}
              id="previous"
              variant="contained"
              onClick={previousStep}
            >
              Previous step
            </Button>
          )}
          <ButtonAsync
            id="next"
            variant="contained"
            color="primary"
            disabled={disableNextStep()}
            loading={upsertPortfolioContentConfigLoading}
            onClick={() => {
              if (step === 2) {
                const nextFunnelStep = getNextFunnelStep();
                if (!nextFunnelStep) {
                  !moreThanOneImpactGroup ? handleSubmit() : nextStep();
                } else {
                  setSelectedFunnelStep({
                    title: nextFunnelStep.title,
                    funnel_step_id: nextFunnelStep.funnelStepId,
                    type: nextFunnelStep.type,
                  });
                }
              } else if (step === 3) {
                handleSubmit();
              } else {
                nextStep();
              }
            }}
            style={{ marginLeft: 'auto' }}
          >
            {step === 2 && !moreThanOneImpactGroup && !getNextFunnelStep()
              ? 'Finish'
              : step !== 3
                ? 'Next step'
                : 'Finish'}
          </ButtonAsync>
        </SidePanelActions>
        {upsertPortfolioContentConfigError ? <ErrorMessage error={upsertPortfolioContentConfigError} /> : null}
      </SidePanel>
    </>
  );
}

export default ContentEditV2;
