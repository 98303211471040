import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';

import dayjs from 'dayjs';
import styled from 'styled-components';
import { BooleanParam, NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';

import { withLabelsProvider } from '../context/LabelsProvider';
import { withPortfolioProvider } from '../context/PortfolioProvider';
import { useUserQuery } from '../graphql/user/queryUser';
import { usePortfolio } from 'context/PortfolioProvider';

import { track } from '../constants/datadog';
import { EVENT } from '../constants/events';
import { FEATURE_FLAGS, PORTFOLIO_FEATURE_FLAGS } from '../constants/featureFlags';
import {
  DATE_SELECTOR_DEFAULT_FORMAT,
  DATE_SELECTOR_YEARLY_DEFAULT_FORMAT,
  djsAnchors,
  format,
  GLOBAL_DATE_FORMAT,
} from '../utils/dates';
import { DEFAULT_PORTFOLIO_DATE_RANGE } from '../utils/portfolio';
import { useQueryParamDateRange } from 'hooks/useQueryParamDateRange';

import ErrorBoundary from '../components/ErrorBoundary';
import ErrorMessage from '../components/ErrorMessage';
import FeatureSwitch from '../components/FeatureSwitch';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import MainContent from '../components/MainContent';
import PortfolioFeatureSwitch from '../components/PortfolioFeatureSwitch';
import PortfolioHeader from '../components/PortfolioHeader';
import { PortfolioTypeSwitch } from '../components/PortfolioTypeSwitch/PortfolioTypeSwitch';
import { Tabs, TabsContent, TabsNav } from '../components/Tabs';
import { LaunchOptimization } from './portfolio/components/LaunchOptimization';
import SimulationCreate from './portfolio/components/Simulations/SimulationCreate';
import { DateSelector, DateSelectorProps, getPortfolioDateRanges } from 'components/DateSelector';
import SwitchSquare from 'components/Switch/SwitchSquare';

import * as Styles from './portfolio/styles/Portfolio';
import { BricksWrapper } from './portfolio/styles/Portfolio';

import { portfolioTabs } from '../configs/portfolio';
import Budget from './portfolio/Budget';
import Content from './portfolio/Content';
import OldBudget from './portfolio/OldBudget/OldBudget';
import Optimize from './portfolio/Optimize';
import Performance from './portfolio/Performance';
import { Simulations } from './portfolio/Simulations';
import Target from './portfolio/Target';
import { Validation } from './portfolio/Validation';
import ButtonIcon from '../components/ButtonIcon';
import { CirclePlus, X } from 'lucide-react';
import Tooltip from '../components/Tooltip';
import { usePortfolioV2MetaQuery } from '../graphql/portfolio/queryPortfolioMeta';
import { getCompareDateRanges } from '../components/DateSelector/portfolioDateRanges';
import { nexyColors } from '../theme';
import { useParams } from 'react-router';
import { withImpactGroupsProvider } from '../context/ImpactGroupsProvider';
import { useFunnelStepsV2Query } from '../graphql/portfolio/queryFunnelSteps';
import { toNumber } from 'lodash';

export const LoadingWrapStyled = styled.div`
  & > div:first-child {
    margin-bottom: 50px;
  }

  .section {
    &:nth-child(2) {
      margin-bottom: 50px;
    }

    &:nth-child(2) > div {
      height: 350px;
      opacity: 0.75;
    }

    &:nth-child(3) > div {
      height: 400px;
      opacity: 0.35;
    }
  }
`;

export const HeaderStyled = styled.div`
  flex-direction: row;
  position: relative;
  display: flex;
  align-items: center;
`;

export const LoadingStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;

  & > div:last-child {
    flex: 1;
  }
`;

export const AvatarLoader = styled(LoadingPlaceholder)`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  margin-right: 15px;
`;

export const TitleLoader = styled(LoadingPlaceholder)`
  height: 35px;
  max-width: 650px;
  margin-bottom: 6px;
`;

export const BrickLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BrickLoader = styled(LoadingPlaceholder)`
  height: 20px;
  width: 150px;
  margin-bottom: 6px;
`;

export const SubtitleLoader = styled(LoadingPlaceholder)`
  height: 20px;
  width: 550px;
  margin-bottom: 6px;
`;

export const WrapBricksStyled = styled.div`
  display: flex;
  justify-content: space-between;

  .NEXYHeaderBrick {
    margin-right: 32px;
  }
`;

const ALLOWED_TABS = [
  portfolioTabs.PERFORMANCE,
  portfolioTabs.OPTIMIZATION,
  portfolioTabs.VALIDATION,
  portfolioTabs.BUDGET,
  portfolioTabs.TARGET,
  portfolioTabs.CONTENT,
  portfolioTabs.SIMULATIONS,
];

const Portfolio = () => {
  const { data: userData } = useUserQuery();
  const { portfolioID } = useParams();
  const portfolioId = parseInt(portfolioID, 10);
  const {
    budgetChart: { visiblePlannedChart, setVisiblePlannedChart },
    selectedFunnelStep: { selectedFunnelStep, setSelectedFunnelStep },
    portfolioV2Info: {
      meta: { updateState: updatePortfolioMetaState, data: portfolioMeta },
    },
  } = usePortfolio();

  const [defaultDatePickerComparisonOpen, setDefaultDatePickerComparisonOpen] = useState(false);

  const [activeTab = portfolioTabs.PERFORMANCE, setActiveTab] = useQueryParam('activeTab', StringParam);
  const [queryParams, setQueryParams] = useQueryParams({
    fs: NumberParam,
    simulationId: NumberParam,
    activeTab: StringParam,
    selectedScenarioId: NumberParam,
    scenarioMetricSwitch: StringParam,
    xAxis: StringParam,
    yAxis: StringParam,
    dateComparisonActive: BooleanParam,
  });
  const { dateFrom, dateTo, setDates } = useQueryParamDateRange(DEFAULT_PORTFOLIO_DATE_RANGE);
  const compareDateRanges = getCompareDateRanges(dateFrom, dateTo);

  const {
    dateFrom: compareFrom,
    dateTo: compareTo,
    setDates: setCompare,
  } = useQueryParamDateRange(
    queryParams.dateComparisonActive
      ? {
          dateFrom: compareDateRanges.previousPeriod.getDateRange().from,
          dateTo: compareDateRanges.previousPeriod.getDateRange().to,
        }
      : null,
    { dateFrom: 'compareFrom', dateTo: 'compareTo' },
  );
  const {
    data: portfolioMetaData,
    loading: metaLoading,
    error,
  } = usePortfolioV2MetaQuery({
    start: dayjs(dateFrom).utc().format(GLOBAL_DATE_FORMAT),
    end: dayjs(dateTo).utc().format(GLOBAL_DATE_FORMAT),
    portfolioId,
  });

  const onFunnelStepQueryCompleted = useCallback(
    (data) => {
      const funnelSteps = data?.portfolioV2?.funnelSteps;
      if (funnelSteps?.length) {
        let targetFunnelStepId = null;

        // Check if queryParams.fs exists in the funnelSteps
        if (queryParams.fs && funnelSteps.some((fs) => fs?.funnelStepId === queryParams.fs)) {
          targetFunnelStepId = queryParams.fs;
        } else if (portfolioMeta?.defaultOptimizationTarget?.funnelStepId) {
          // Use defaultOptimizationTarget if queryParams.fs is invalid
          targetFunnelStepId = portfolioMeta.defaultOptimizationTarget.funnelStepId;
        }

        // Select the target funnel step or fall back to the last one
        const targetFunnelStep =
          funnelSteps.find((fs) => fs?.funnelStepId === targetFunnelStepId) || funnelSteps[funnelSteps.length - 1];

        // Set the selected funnel step in state
        setSelectedFunnelStep({
          title: targetFunnelStep?.title,
          funnel_step_id: targetFunnelStep.funnelStepId,
          type: targetFunnelStep.type,
        });

        // Update query params to reflect the selected funnel step
        if (targetFunnelStep?.funnelStepId !== queryParams.fs) {
          setQueryParams({ fs: targetFunnelStep?.funnelStepId ? toNumber(targetFunnelStep?.funnelStepId) : undefined });
        }
      }
    },
    [portfolioMeta?.defaultOptimizationTarget?.funnelStepId, queryParams.fs],
  );

  useFunnelStepsV2Query({
    portfolioId,
    onCompleted: onFunnelStepQueryCompleted,
  });

  useEffect(() => {
    // Updating the legacy data, at the moment of writing this comment it's only being used in the Content tab
    updatePortfolioMetaState({ data: portfolioMetaData?.portfolioV2, loading: metaLoading });
  }, [portfolioMetaData, metaLoading]);

  const handleTabChange = (tab: string) => {
    // We're actively tracking the tab changes in datadog
    setActiveTab(tab);
    track(EVENT.PORTFOLIO_TAB_CHANGE(tab), {
      portfolioId,
      start: dayjs(dateFrom).format(GLOBAL_DATE_FORMAT),
      end: dayjs(dateTo).format(GLOBAL_DATE_FORMAT),
    });
  };

  const hasPortfolioEnded = !metaLoading && dayjs().isAfter(portfolioMeta?.end);
  const isSupportUser = userData?.user?.activeRole?.name?.includes('support');

  // format dates differently if the selected range spans across different years
  const dateFormat = useMemo(() => {
    if (!queryParams.dateComparisonActive) {
      return null;
    }

    const compareFromYear = dayjs(compareFrom).year();
    const compareToYear = dayjs(compareTo).year();
    const dateFromYear = dayjs(dateFrom).year();
    const dateToYear = dayjs(dateTo).year();

    // Check if any of the date ranges span different years
    if (compareFromYear !== compareToYear || compareFromYear !== dateFromYear || compareToYear !== dateToYear) {
      return DATE_SELECTOR_YEARLY_DEFAULT_FORMAT;
    }
  }, [compareFrom, compareTo, dateFrom, dateTo]);

  const getDateSelectorProps = useMemo(
    (): DateSelectorProps => ({
      onDateChange: (inp: { from: Date; to: Date }) => {
        setDates({ dateFrom: inp.from, dateTo: inp.to });
        track(EVENT.DATE_RANGE_CHANGE, {
          portfolioId,
          tab: activeTab,
          start: dayjs(inp.from).format(GLOBAL_DATE_FORMAT),
          end: dayjs(inp.to).format(GLOBAL_DATE_FORMAT),
        });
      },
      dateFrom,
      dateTo,
      hideFutureQuickSelection: true,
      disableBeforeDate: new Date(format(dayjs(portfolioMeta?.start), 'utcStartMidnight')),
      disableAfterDate:
        activeTab === portfolioTabs.BUDGET || activeTab === portfolioTabs.TARGET
          ? new Date(format(portfolioMeta?.end, 'utcStartMidnight', true))
          : hasPortfolioEnded
            ? new Date(format(portfolioMeta?.end, 'utcStartMidnight', true))
            : new Date(format(djsAnchors.today, 'utcStartMidnight', true)),
      dateRanges: getPortfolioDateRanges(
        new Date(portfolioMeta?.start),
        hasPortfolioEnded
          ? new Date(format(portfolioMeta?.end, 'utcMidday', true))
          : new Date(format(dayjs().utc(), 'utcMidday', true)),
      ),
      useNexoyaDateRanges: true,
      format: queryParams.dateComparisonActive ? dateFormat : DATE_SELECTOR_DEFAULT_FORMAT,
      renderStartAdornment: queryParams.dateComparisonActive ? () => null : null,
    }),
    [dateFrom, dateTo, portfolioMeta?.start, portfolioMeta?.end, hasPortfolioEnded, activeTab],
  );

  const getCompareDateSelectorProps = useMemo(
    (): DateSelectorProps => ({
      onDateChange: (inp: { from: Date; to: Date }) => {
        setCompare({ dateFrom: inp.from, dateTo: inp.to });
        track(EVENT.COMPARE_DATE_RANGE_CHANGE, {
          portfolioId,
          tab: activeTab,
          start: dayjs(inp.from).format(GLOBAL_DATE_FORMAT),
          end: dayjs(inp.to).format(GLOBAL_DATE_FORMAT),
        });
      },
      dateFrom: compareFrom || null,
      dateTo: compareTo || null,
      hideFutureQuickSelection: true,
      hidePastQuickSelection: false,
      disableAfterDate: getDateSelectorProps.dateTo
        ? new Date(format(dayjs(getDateSelectorProps.dateTo), 'utcStartMidnight'))
        : null,
      dateRanges: compareDateRanges,
      useNexoyaDateRanges: false,
      format: dateFormat,
      renderStartAdornment: () => (
        <Tooltip placement="left" variant="dark" size="small" content="Cancel comparison">
          <ButtonIcon
            style={{ borderRadius: '50%', padding: 2, marginRight: 4 }}
            onClick={(e) => {
              e.preventDefault();
              // @ts-ignore
              setQueryParams({ dateComparisonActive: false, compareFrom: null, compareTo: null });
            }}
          >
            <X className="h-2.5 w-2.5 text-neutral-300" />
          </ButtonIcon>
        </Tooltip>
      ),
    }),
    [compareFrom, compareTo, portfolioMeta?.start, portfolioMeta?.end, hasPortfolioEnded, activeTab],
  );

  const renderMetaLoadingSkeleton = () => (
    <LoadingWrapStyled>
      <HeaderStyled>
        <LoadingStyled>
          <div>
            <TitleLoader />
            <SubtitleLoader />
            <BricksWrapper>
              <BrickLoaderWrapper>
                <AvatarLoader style={{ width: 32, height: 32 }} />
                <div>
                  <BrickLoader style={{ width: 100 }} />
                  <BrickLoader />
                </div>
              </BrickLoaderWrapper>
              <BrickLoaderWrapper>
                <AvatarLoader style={{ width: 32, height: 32 }} />
                <div>
                  <BrickLoader style={{ width: 100 }} />
                  <BrickLoader />
                </div>
              </BrickLoaderWrapper>
              <BrickLoaderWrapper>
                <AvatarLoader style={{ width: 32, height: 32 }} />
                <div>
                  <BrickLoader style={{ width: 100 }} />
                  <BrickLoader />
                </div>
              </BrickLoaderWrapper>
            </BricksWrapper>
          </div>
        </LoadingStyled>
      </HeaderStyled>
    </LoadingWrapStyled>
  );

  return (
    <MainContent className="sectionToPrint">
      <ErrorBoundary>
        {metaLoading ? (
          renderMetaLoadingSkeleton()
        ) : (
          <>
            <PortfolioHeader
              portfolioId={portfolioId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              selectedFunnelStep={selectedFunnelStep}
            />
          </>
        )}
        <>
          <Tabs
            defaultTab={ALLOWED_TABS.includes(activeTab) ? activeTab : portfolioTabs.PERFORMANCE}
            controlledTab={ALLOWED_TABS.includes(activeTab) ? activeTab : portfolioTabs.PERFORMANCE}
          >
            <Styles.TabsNavWrapperStyled>
              <TabsNav
                tab={portfolioTabs.PERFORMANCE}
                component={Styles.NavTabStyled}
                onClick={() => handleTabChange(portfolioTabs.PERFORMANCE)}
              >
                Performance
              </TabsNav>
              <TabsNav
                tab={portfolioTabs.OPTIMIZATION}
                component={Styles.NavTabStyled}
                onClick={() => handleTabChange(portfolioTabs.OPTIMIZATION)}
              >
                Optimize
              </TabsNav>
              <TabsNav
                tab={portfolioTabs.VALIDATION}
                component={Styles.NavTabStyled}
                onClick={() => handleTabChange(portfolioTabs.VALIDATION)}
              >
                Validation
              </TabsNav>
              <PortfolioTypeSwitch
                renderForBudgetType={() => (
                  <TabsNav
                    tab={portfolioTabs.BUDGET}
                    component={Styles.NavTabStyled}
                    onClick={() => handleTabChange(portfolioTabs.BUDGET)}
                  >
                    Budget
                  </TabsNav>
                )}
                renderForTargetType={() => (
                  <TabsNav
                    tab={portfolioTabs.TARGET}
                    component={Styles.NavTabStyled}
                    onClick={() => handleTabChange(portfolioTabs.TARGET)}
                  >
                    Target
                  </TabsNav>
                )}
              />

              <TabsNav
                tab={portfolioTabs.CONTENT}
                component={Styles.NavTabStyled}
                onClick={() => handleTabChange(portfolioTabs.CONTENT)}
              >
                Content
              </TabsNav>
              <FeatureSwitch
                features={[FEATURE_FLAGS.SIMULATIONS]}
                renderOld={() => null}
                renderNew={() => (
                  <TabsNav
                    tab={portfolioTabs.SIMULATIONS}
                    component={Styles.NavTabStyled}
                    onClick={() => {
                      setQueryParams({
                        activeTab: portfolioTabs.SIMULATIONS,
                        simulationId: null,
                        selectedScenarioId: null,
                        scenarioMetricSwitch: null,
                        xAxis: null,
                        yAxis: null,
                      });
                      track(EVENT.PORTFOLIO_TAB_CHANGE(portfolioTabs.SIMULATIONS), {
                        portfolioId,
                      });
                    }}
                  >
                    Simulations
                  </TabsNav>
                )}
              />

              <Styles.TimeSpanWrap
                lastDivHeight={queryParams.dateComparisonActive ? 'fit-content' : '100%'}
                bottomBorderRadius={queryParams.dateComparisonActive ? 5 : 0}
              >
                {activeTab === portfolioTabs.PERFORMANCE ? (
                  !queryParams.dateComparisonActive ? (
                    <>
                      <Tooltip placement="left" variant="dark" size="small" content="Compare timeperiods">
                        <ButtonIcon
                          onClick={() => {
                            setQueryParams({ dateComparisonActive: true });
                            setDefaultDatePickerComparisonOpen(true);
                          }}
                          style={{ marginRight: 8 }}
                        >
                          <CirclePlus className="h-5 w-5 text-neutral-300" />
                        </ButtonIcon>
                      </Tooltip>
                      <DateSelector {...getDateSelectorProps} />
                    </>
                  ) : (
                    <div className="flex h-full items-center justify-center gap-4 rounded-t-md border border-b-0 bg-neutral-50 p-2">
                      <DateSelector
                        {...getCompareDateSelectorProps}
                        defaultDatePickerOpen={defaultDatePickerComparisonOpen}
                        style={{
                          fontSize: 12,
                          height: 'fit-content',
                          padding: '6px 4px 6px 10px',
                          color: nexyColors.lilac,
                        }}
                      />
                      <span className="my-auto h-full content-center text-lg font-light text-cloudyBlue">vs</span>
                      <DateSelector
                        style={{
                          fontSize: 12,
                          height: 'fit-content',
                          padding: '6px 4px 6px 10px',
                          color: nexyColors.greenTeal,
                        }}
                        {...getDateSelectorProps}
                      />
                    </div>
                  )
                ) : null}
                {activeTab === portfolioTabs.VALIDATION || activeTab === portfolioTabs.TARGET ? (
                  <DateSelector {...getDateSelectorProps} />
                ) : null}

                {activeTab === portfolioTabs.BUDGET ? (
                  <PortfolioFeatureSwitch
                    features={[PORTFOLIO_FEATURE_FLAGS.BUDGET_V1]}
                    renderNew={() => null}
                    renderOld={() => <DateSelector {...getDateSelectorProps} />}
                  />
                ) : null}
                {activeTab === portfolioTabs.OPTIMIZATION && isSupportUser ? (
                  <LaunchOptimization portfolioId={portfolioId} />
                ) : null}

                {activeTab === portfolioTabs.SIMULATIONS ? (
                  <FeatureSwitch
                    features={[FEATURE_FLAGS.SIMULATIONS]}
                    renderOld={() => null}
                    renderNew={() =>
                      !queryParams.simulationId ? (
                        <SimulationCreate portfolioId={portfolioId} startDate={dateFrom} endDate={dateTo} />
                      ) : null
                    }
                  />
                ) : null}
              </Styles.TimeSpanWrap>

              {activeTab === portfolioTabs.BUDGET && (
                <PortfolioFeatureSwitch
                  features={[PORTFOLIO_FEATURE_FLAGS.BUDGET_V1]}
                  renderNew={() => (
                    <Styles.SwitchWrapper>
                      <SwitchSquare
                        style={{
                          margin: '0 0 0 0',
                        }}
                        isOn={!visiblePlannedChart}
                        onToggle={() => setVisiblePlannedChart(!visiblePlannedChart)}
                        activeText="Planned"
                        inactiveText="Spent"
                      />
                    </Styles.SwitchWrapper>
                  )}
                  renderOld={() => null}
                />
              )}
            </Styles.TabsNavWrapperStyled>
            <TabsContent tab={portfolioTabs.PERFORMANCE}>
              <ErrorBoundary>
                {activeTab === portfolioTabs.PERFORMANCE && (
                  <Performance
                    portfolioId={portfolioId}
                    dateSelectorProps={getDateSelectorProps}
                    comparisonDateSelectorProps={getCompareDateSelectorProps}
                  />
                )}
              </ErrorBoundary>
            </TabsContent>
            <TabsContent tab={portfolioTabs.VALIDATION}>
              <ErrorBoundary>
                {activeTab === portfolioTabs.VALIDATION ? (
                  <Validation portfolioId={portfolioId} dateTo={dateTo} dateFrom={dateFrom} />
                ) : null}
              </ErrorBoundary>
            </TabsContent>
            <TabsContent tab={portfolioTabs.CONTENT}>
              <ErrorBoundary>
                {activeTab === portfolioTabs.CONTENT && (
                  <Content dateFrom={dateFrom} dateTo={dateTo} portfolioId={portfolioId} />
                )}
              </ErrorBoundary>
            </TabsContent>
            <TabsContent tab={portfolioTabs.BUDGET}>
              <ErrorBoundary>
                {activeTab === portfolioTabs.BUDGET && (
                  <PortfolioFeatureSwitch
                    features={[PORTFOLIO_FEATURE_FLAGS.BUDGET_V1]}
                    renderNew={() => <OldBudget dateFrom={dateFrom} dateTo={dateTo} portfolioId={portfolioId} />}
                    renderOld={() => (
                      <Budget
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        portfolioStart={portfolioMetaData?.portfolioV2?.start}
                        portfolioEnd={portfolioMetaData?.portfolioV2?.end}
                        portfolioId={portfolioId}
                      />
                    )}
                  />
                )}
              </ErrorBoundary>
            </TabsContent>
            <TabsContent tab={portfolioTabs.TARGET}>
              <ErrorBoundary>
                {activeTab === portfolioTabs.TARGET && (
                  <Target
                    portfolioStart={portfolioMetaData?.portfolioV2?.start}
                    portfolioEnd={portfolioMetaData?.portfolioV2?.end}
                    portfolioId={portfolioId}
                    portfolioType={portfolioMeta?.type}
                  />
                )}
              </ErrorBoundary>
            </TabsContent>
            <TabsContent tab={portfolioTabs.OPTIMIZATION}>
              <ErrorBoundary>
                {activeTab === portfolioTabs.OPTIMIZATION && <Optimize portfolioId={portfolioId} />}
              </ErrorBoundary>
            </TabsContent>
            <FeatureSwitch
              features={[FEATURE_FLAGS.SIMULATIONS]}
              renderOld={() => null}
              renderNew={() => (
                <TabsContent tab={portfolioTabs.SIMULATIONS}>
                  <ErrorBoundary>
                    {activeTab === portfolioTabs.SIMULATIONS && <Simulations portfolioId={portfolioId} />}
                  </ErrorBoundary>
                </TabsContent>
              )}
            />
          </Tabs>
        </>
      </ErrorBoundary>
      {error ? <ErrorMessage error={error} /> : null}
    </MainContent>
  );
};

export default withRouter(withPortfolioProvider(withImpactGroupsProvider(withLabelsProvider(Portfolio))));
