import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { get } from 'lodash';
import { nexyColors } from 'theme';
import { BooleanParam, DateParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { NexoyaFunnelStepType, NexoyaImpactGroup, NexoyaOptimizationV2, NexoyaPortfolioLabel } from 'types';

import { useLabels } from '../../context/LabelsProvider';
import { useTranslationsQuery } from '../../graphql/translation/queryTranslations';
import { usePortfolio } from 'context/PortfolioProvider';
import { useValidationQuery } from 'graphql/portfolio/queryValidation';
import { useTeamQuery } from 'graphql/team/queryTeam';

import useTeamColor from '../../hooks/useTeamColor';
import { capitalizeWords } from '../../utils/string';
import translate from '../../utils/translate';
import { addExportMenu } from './utils/addExportMenu';
import { addWatermark } from './utils/addWatermark';
import { useGetSeriesColor } from 'utils/chart';
import { djsAnchors, isLaterDay } from 'utils/dates';

import Logo from 'components/Logo/LogoIcon';
import Typography from 'components/Typography';

import * as Styles from './styles/PortfolioPerformanceChart';
import { NexyChartClasses } from './styles/PortfolioPerformanceChart';

import { portfolioTabs } from '../../configs/portfolio';
import { getPercentageColor } from '../../utils/number';
import { getLongerPeriod } from './utils/period';
import { useImpactGroups } from '../../context/ImpactGroupsProvider';

type ValidationDataType = Record<string, any>[];
interface Props {
  data: Record<string, any>[];
  validationData: ValidationDataType;
  validationTooltip: Record<string, any>;
  portfolioId: number;
  optimizations: NexoyaOptimizationV2[];
  portfolioTitle?: string;
}

am4core.useTheme(am4themes_animated);

dayjs.extend(isBetween);

function PortfolioPerformanceChart({
  data,
  validationData = [],
  validationTooltip = {},
  portfolioId,
  optimizations,
  portfolioTitle,
}: Props) {
  const CHART_CONTAINER_ID = 'portfolio-performance-chart' + portfolioId;

  const [qp] = useQueryParams({
    compareFrom: StringParam,
    compareTo: StringParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    dateComparisonActive: BooleanParam,
  });

  const chartRef = useRef(null);
  const [activeOptimization, setActiveOptimization] = useState<NexoyaOptimizationV2>(null);
  const [validationTooltipVisible, setValidationTooltipVisible] = useState<boolean>(false);
  const [validationTooltipOffset, setValidationTooltipOffset] = useState<number>(0);
  const [activeBullet, setActiveBullet] = useState();

  const { data: translationData } = useTranslationsQuery();
  const {
    performanceChart: { isStackedAreaChartActive },
    selectedFunnelStep: { selectedFunnelStep: selectedPartialFunnelStep },
    portfolioV2Info: {
      funnelSteps: { data: funnelSteps },
      meta: { data: portfolioMeta },
    },
  } = usePortfolio();

  const {
    providers: { providersFilter },
  } = usePortfolio();

  const {
    labelById,
    filter: { labelsFilter },
  } = useLabels();

  const {
    filter: { impactGroupsFilter },
  } = useImpactGroups();

  const getThemeColor = useTeamColor();

  const translations = get(translationData, 'translations', []);
  const selectedFunnelStep = funnelSteps?.find(
    (fs) => fs?.funnelStep?.funnelStepId === selectedPartialFunnelStep.funnel_step_id,
  )?.funnelStep;
  const optimizationTarget = portfolioMeta?.defaultOptimizationTarget;

  const { data: validationTooltipData, loading } = useValidationQuery({
    portfolioId,
    dateFrom: dayjs.utc(activeOptimization?.start).format(),
    dateTo: dayjs.utc(activeOptimization?.end).format(),
    skip: !validationTooltipVisible || !activeOptimization,
    funnelStepId: selectedFunnelStep?.funnelStepId,
  });
  const validationDataTotal = get(validationTooltipData, 'validation.validation.tooltip.validationDataTotal', {});
  const teamQuery = useTeamQuery({
    withMembers: false,
    withOrg: false,
  });
  const numberFormat = get(teamQuery, 'data.team.number_format', 'de-CH') || 'de-CH';
  // tab stuff
  const [, setQueryParams] = useQueryParams({
    activeTab: StringParam,
    optimizationId: NumberParam,
    dateFrom: DateParam,
    dateTo: DateParam,
  });

  const navigateToOptimizations = useCallback(
    () =>
      setQueryParams({
        activeTab: 'optimization',
      }),
    [setQueryParams],
  );

  const getSeriesColor = useGetSeriesColor();
  const targetOptimizations = optimizations?.filter((optimization) => {
    const isStartDateInFuture = isLaterDay(optimization.start, djsAnchors.today);
    const isEndDateInFuture = isLaterDay(optimization.end, djsAnchors.today);
    return (
      optimizationTarget?.funnelStepId === selectedFunnelStep?.funnelStepId &&
      !isStartDateInFuture &&
      !isEndDateInFuture
    );
  });

  // hide tooltip if we switch funnel steps
  useEffect(() => {
    if (validationTooltipVisible) {
      setValidationTooltipVisible(false);
      setActiveBullet(null);
    }
  }, [selectedFunnelStep?.funnelStepId]);

  const createRanges = useCallback(
    (dateAxis) => {
      targetOptimizations?.forEach((optimization) => {
        const range = dateAxis.axisRanges.create();
        range.date = new Date(optimization.start);
        range.endDate = new Date(optimization.end);
        range.grid.disabled = true;
        range.axisFill.fill = am4core.color('#F7F7F8');
        range.axisFill.fillOpacity = activeBullet === range.endValue ? 1 : 0;

        range.bullet = new am4core.Image();
        range.bullet.width = 30;
        range.bullet.height = 30;
        range.bullet.dy = -17;
        range.bullet.href = 'https://cdn.nexoya.io/img/99_speed_icon.svg';
        range.bullet.horizontalCenter = 'middle';
        range.bullet.interactionsEnabled = true;
        range.bullet.above = true;
        range.bullet.zIndex = 20;
        range.bullet.events.on('hit', (ev) => {
          // if tooltip is already visible
          if (validationTooltipVisible) {
            // if click target is not the same as already visible:
            // open new target tooltip
            if (ev.target.dataItem.endValue !== activeBullet) {
              setValidationTooltipVisible(true);
              setActiveBullet(ev.target.dataItem.endValue);
              setActiveOptimization(optimization);
            } else {
              // close opened one
              setValidationTooltipVisible(false);
              setActiveBullet(null);
              setActiveOptimization(null);
            }
          } else {
            // open target tooltip
            setValidationTooltipVisible(true);
            setActiveBullet(ev.target.dataItem.endValue);
            setActiveOptimization(optimization);
          }
          return setValidationTooltipOffset(ev.svgPoint.x);
        });
        return range;
      });
    },
    [activeBullet, targetOptimizations, validationTooltipVisible],
  );

  const createBullet = (series, lineColor, isFilter) => {
    if (!isFilter) {
      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.radius = 3;
      bullet.fill = am4core.color(lineColor);
      bullet.fillOpacity = 1;
      bullet.circle.strokeWidth = 1;
      bullet.strokeOpacity = 0;
      bullet.circle.stroke = am4core.color('#fff');

      const bulletHoverState = bullet.states.create('hover');
      bulletHoverState.properties.scale = 2;
      bulletHoverState.properties.strokeOpacity = 1;
    }
  };

  const configureSeries = (series, fieldX, fieldY, name, lineColor, isDashed, showArea, isFilter, hasBackdrop) => {
    series.name = name;
    series.id = fieldY;
    series.dataFields.valueY = fieldY?.toString();
    series.dataFields.dateX = fieldX;
    series.strokeWidth = 3;
    series.stroke = am4core.color(lineColor);

    if (isDashed) {
      series.strokeDasharray = '16,6';
    }

    if (showArea) {
      series.dataFields.openValueY = 'baselinePerformanceRelative';
      series.fill = series.stroke;
      series.fillOpacity = 0.1;
    }

    if (hasBackdrop) {
      const gradient = new am4core.LinearGradient();
      gradient.addColor(am4core.color('#C7C8D1'), 0.3);
      gradient.addColor(am4core.color('#C7C8D1'), 0.3);
      gradient.rotation = 90; // Vertical gradient

      // Apply gradient to series
      series.fill = gradient;
      series.fillOpacity = 1;
      series.fillModifier = new am4core.LinearGradientModifier();
    }

    if (isFilter) {
      series.fill = series.stroke;
      series.fillOpacity = 1;
      series.tensionX = 1;
      series.tensionY = 1;
      series.stacked = true;
    }

    if (name === 'Potential') {
      series.zIndex = 20;
    }
  };

  const createTooltip = (series, groupTooltip, isFilter) => {
    series.tooltip.pointerOrientation = 'down';
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fillOpacity = 1;
    series.tooltip.background.fill = am4core.color(nexyColors.darkGrey);
    series.tooltip.background.stroke = am4core.color(nexyColors.darkGrey);
    series.tooltip.label.fontSize = 12;
    series.tooltip.background.pointerLength = 0;
    series.tooltip.label.paddingLeft = 0;
    series.tooltip.label.paddingRight = 0;
    series.tooltip.label.paddingBottom = 0;
    series.tooltip.label.interactionsEnabled = true;
    series.tooltip.clickable = true;
    series.tooltip.keepTargetHover = true;
    series.tooltip.dy = -5;
    series.tooltip.animationDuration = 150;
    series.tooltip.animationEasing = am4core.ease.sinOut;

    function getTooltipHTML(selectedFunnelStep) {
      return `<div class="${NexyChartClasses.tooltip}">{dateX}</div>
          <div style="padding:12px;display: flex;justify-content: space-between; gap: 16px; min-width: 125px">
            <span style="color: #C7C8D1; font-weight: 300">${capitalizeWords(selectedFunnelStep?.title || '')}</span>
            ${
              selectedFunnelStep?.type === NexoyaFunnelStepType.Conversion
                ? `{valueY.formatNumber('#.0')}`
                : `{valueY.formatNumber('#.00a')}`
            }
          </div>`;
    }

    const getComparisonTooltip = (series) => {
      return series.adapter.add('tooltipHTML', function () {
        const chart = series.chart;
        const currentIndex = series.tooltipDataItem.index;
        const dataItem = chart.data[currentIndex];

        let tooltipContent = `
          <div class="${NexyChartClasses.tooltip}" style="text-align: left;">
            <div style="
              color: #C7C8D1;
              font-weight: 300;
              margin-bottom: 4px;
            ">
              ${capitalizeWords(selectedFunnelStep?.title || '')}
            </div>
            <div style="
              display: flex;
              flex-direction: column;
            ">
        `;

        if (dataItem?.timestampComparison && dataItem?.valueTimeComparison !== null) {
          tooltipContent += `
            <div style="
              padding: 6px;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              gap: 16px;
              min-width: 125px;
              margin-bottom: 0;
            ">
              <div style="
                display: flex;
                flex-direction: column;
                gap: 4px;
              ">
                <div style="
                  display: flex;
                  align-items: center;
                ">
                  <div style="
                    width: 14px;
                    height: 2px;
                    border-top: 2px dashed ${getSeriesColor('past')};
                    margin-right: 4px;
                  "></div>
                  <span style="
                    color: ${nexyColors.paleGrey};
                    font-size: 12px;
                    text-transform: capitalize;
                  ">
                    ${dayjs(dataItem?.timestampComparison).format('ddd, D MMM, YYYY')}
                  </span>
                </div>
              </div>
              <div style="
                display: flex;
                flex-direction: column;
                gap: 4px;
              ">
                <span style="
                  color: ${nexyColors.white};
                  font-weight: 500;
                ">
                  ${chart.numberFormatter.format(dataItem?.valueTimeComparison, '#.00a')}
                </span>
              </div>
            </div>
          `;
        }

        if (dataItem?.timestamp && dataItem.value !== null) {
          tooltipContent += `
            <div style="
              padding: 6px;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              gap: 16px;
              min-width: 125px;
              margin-bottom: 0;
            ">
              <div style="
                display: flex;
                flex-direction: column;
                gap: 4px;
              ">
                <div style="
                  display: flex;
                  align-items: center;
                ">
                  <div style="
                    width: 14px;
                    height: 2px;
                    background-color: ${getSeriesColor('potential')};
                    margin-right: 4px;
                  "></div>
                  <span style="
                    color: ${nexyColors.paleGrey};
                    font-size: 12px;
                    text-transform: capitalize;
                  ">
                    ${dayjs(dataItem?.timestamp).format('ddd, D MMM, YYYY')}
                  </span>
                </div>
                ${
                  dataItem.comparisonChangePercent
                    ? `
                <span style="
                  color: ${nexyColors.blueyGrey};
                  font-weight: 400;
                  font-size: 10px;
                  text-transform: lowercase;
                ">
                  % diff. from prev. period
                </span>
                `
                    : ''
                }
              </div>
              <div style="
                display: flex;
                flex-direction: column;
                gap: 5px;
                justify-content: flex-end;
                align-items: flex-end;
              ">
                <span style="
                  color: ${nexyColors.white};
                  font-weight: 500;
                ">
                  ${chart.numberFormatter.format(dataItem.value, '#.00a')}
                </span>
                ${
                  dataItem?.comparisonChangePercent
                    ? `
                
                <span style="
                  color: ${getPercentageColor(dataItem.comparisonChangePercent, false)};
                  font-weight: 600;
                  font-size: 10px;
                  text-align: end;
                ">
                  ${
                    (dataItem.comparisonChangePercent < 0 ? '' : '+') +
                    chart.numberFormatter.format(dataItem.comparisonChangePercent, '#.00a')
                  }%
                </span>
                `
                    : ''
                }
                
              </div>
            </div>`;
        }

        tooltipContent += `
        </div>
      </div>
    `;

        return tooltipContent;
      });
    };

    const createGroupTooltip = (series) => {
      const excludeEffective = (item) => item.name !== 'Effective';
      const excludePredictedOrPotential = (item) => !(item.name === 'Predicted' || item.name === 'Potential');

      const shouldRender = series.properties.name === 'Effective' ? excludePredictedOrPotential : excludeEffective;

      const generateTooltipContent = (item, index, totalItems) => {
        const commonStyle =
          'padding:12px;display: flex;justify-content: space-between; align-items: baseline; gap: 16px; min-width: 125px;';
        const marginBottom = index < totalItems - 1 ? 'margin-bottom: -24px;' : 'margin-bottom: 0;';
        const tooltipStyle = `${commonStyle} ${marginBottom}`;

        const itemName = item.name === 'Effective' ? `Total ${selectedFunnelStep?.title?.toLowerCase()}` : item.name;

        return `
      <div style="${tooltipStyle}">
        <span style="color: #C7C8D1; font-weight: 300;">
          <span style="font-size: 16px; margin-right: 2px; color:${item.stroke}">●</span>
          ${itemName}
        </span>
        {${item.dataFields.valueY}.formatNumber('#.00a')}
      </div>`;
      };

      series.adapter.add('tooltipHTML', function () {
        const seriesList = chartRef.current.series.values.filter(shouldRender);
        const tooltipContent = seriesList.reduce((content, item, index) => {
          return content + generateTooltipContent(item, index, seriesList.length);
        }, `<div class="${NexyChartClasses.tooltip}">{dateX}</div>`);

        return tooltipContent;
      });
    };

    if (qp?.dateComparisonActive) {
      series.tooltipHTML = getComparisonTooltip(series);
      return;
    } else if (groupTooltip) {
      createGroupTooltip(series);
      return;
    } else if (!isFilter) {
      series.tooltipHTML = getTooltipHTML(selectedFunnelStep);
      return;
    }
  };

  const createSeries = useCallback(
    ({
      fieldX,
      fieldY,
      name,
      lineColor,
      isDashed = false,
      showArea = false,
      groupTooltip,
      isFilter = false,
      hasBackdrop = false,
    }) => {
      if (!chartRef.current) return;
      const series = chartRef.current.series.push(new am4charts.LineSeries());

      configureSeries(series, fieldX, fieldY, name, lineColor, isDashed, showArea, isFilter, hasBackdrop);
      createTooltip(series, groupTooltip, isFilter);
      createBullet(series, lineColor, isFilter);

      return series;
    },
    [navigateToOptimizations, validationData, validationTooltip],
  );

  useEffect(() => {
    chartRef.current = am4core.create(CHART_CONTAINER_ID, am4charts.XYChart);
    chartRef.current.data = data;

    chartRef.current.paddingLeft = 0;
    chartRef.current.dateFormatter.dateFormat = 'MMM d, yyyy';
    // Add date axis
    const dateAxis = chartRef.current.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.labels.template.fontSize = 12;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.renderer.labels.template.fill = am4core.color(nexyColors.cloudyBlue80);
    dateAxis.renderer.grid.template.strokeOpacity = 0;
    // Add value axis
    const valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.stroke = am4core.color(nexyColors.paleGrey);
    valueAxis.renderer.grid.template.strokeOpacity = 1;
    valueAxis.renderer.labels.template.fill = am4core.color(nexyColors.cloudyBlue80);
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.renderer.gridContainer.toFront();
    valueAxis.renderer.labels.template.adapter.add('text', (text) => (text ? text.toUpperCase() : text));

    // Provider series first so that it stacks them correctly, otherwise they will be stacked on the first series available
    isStackedAreaChartActive &&
      providersFilter.forEach((provider) => {
        const providerName = translate(translations, provider.name);
        createSeries({
          fieldX: 'timestamp',
          fieldY: provider.provider_id,
          name: providerName,
          lineColor: provider?.providerLogoColor,
          isDashed: false,
          showArea: false,
          groupTooltip: false,
          isFilter: true,
          hasBackdrop: false,
        });
      });

    isStackedAreaChartActive &&
      labelsFilter.forEach((label: NexoyaPortfolioLabel, idx) => {
        const labelName = labelById(label?.labelId).name;
        createSeries({
          fieldX: 'timestamp',
          fieldY: label.labelId,
          name: labelName,
          lineColor: getThemeColor(idx),
          isDashed: false,
          showArea: false,
          groupTooltip: false,
          isFilter: true,
          hasBackdrop: false,
        });
      });

    isStackedAreaChartActive &&
      impactGroupsFilter.forEach((impactGroup: NexoyaImpactGroup, idx) => {
        createSeries({
          fieldX: 'timestamp',
          fieldY: impactGroup?.impactGroupId,
          name: impactGroup?.name,
          lineColor: getThemeColor(idx),
          isDashed: false,
          showArea: false,
          groupTooltip: false,
          isFilter: true,
          hasBackdrop: false,
        });
      });

    const longerPeriodTimestamp = getLongerPeriod(data);

    // Init series
    createSeries({
      fieldX: qp.dateComparisonActive ? longerPeriodTimestamp : 'timestamp',
      fieldY: 'value',
      name: 'Effective',
      lineColor: qp.dateComparisonActive ? getSeriesColor('potential') : getSeriesColor('achieved'),
      isDashed: false,
      showArea: false,
      groupTooltip:
        isStackedAreaChartActive && (labelsFilter?.length || providersFilter?.length || impactGroupsFilter?.length),
      isFilter: false,
      hasBackdrop: isStackedAreaChartActive,
    });

    qp?.dateComparisonActive &&
      createSeries({
        fieldX: longerPeriodTimestamp,
        fieldY: 'valueTimeComparison',
        name: 'Effective',
        lineColor: getSeriesColor('past'),
        isDashed: true,
        showArea: false,
        groupTooltip: true,
        isFilter: false,
        hasBackdrop: isStackedAreaChartActive,
      });

    if (!qp?.dateComparisonActive) {
      createSeries({
        fieldX: 'timestamp',
        fieldY: 'expectedPerformanceRelative',
        name: 'Potential',
        lineColor: getSeriesColor('potential'),
        isDashed: false,
        showArea: true,
        groupTooltip: true,
        isFilter: false,
        hasBackdrop: isStackedAreaChartActive,
      });
      createSeries({
        fieldX: 'timestamp',
        fieldY: 'baselinePerformanceRelative',
        name: 'Predicted',
        lineColor: getSeriesColor('predicted'),
        isDashed: true,
        showArea: false,
        groupTooltip: true,
        isFilter: false,
        hasBackdrop: isStackedAreaChartActive,
      });
    }

    if (qp?.dateComparisonActive) {
      dateAxis.hide();
    }

    createRanges(dateAxis);
    // Add cursor
    chartRef.current.cursor = new am4charts.XYCursor();
    chartRef.current.cursor.maxTooltipDistance = -1;
    // Disable axis lines
    chartRef.current.cursor.lineX.disabled = false;
    chartRef.current.cursor.lineY.disabled = true;
    chartRef.current.cursor.lineX.strokeDasharray = '';
    chartRef.current.cursor.lineX.stroke = nexyColors.blueGrey;

    // Disable axis tooltips
    dateAxis.cursorTooltipEnabled = false;
    valueAxis.cursorTooltipEnabled = false;
    dateAxis.renderer.labels.template.dy = 8;
    // Disable zoom
    chartRef.current.cursor.behavior = 'none';
    chartRef.current.zoomOutButton.disabled = true;
    addExportMenu(chartRef, `${portfolioTitle}_conversions ${selectedFunnelStep?.title?.toLowerCase()}`);
    addWatermark(chartRef);

    return () => {
      // destroy chart in case of re-render
      chartRef.current.dispose();
    };
  }, [
    data,
    createSeries,
    getSeriesColor,
    targetOptimizations,
    activeBullet,
    validationTooltipVisible,
    createRanges,
    providersFilter,
    labelsFilter,
    isStackedAreaChartActive,
  ]);

  // Set localization of chart
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.numberFormatter.numberFormat = '#.0a';
      chartRef.current.numberFormatter.smallNumberThreshold = 0.001;
    }
  }, [numberFormat]);

  const TooltipLoading = () => (
    <Styles.TooltipLoading>
      <div style={{ width: 50 }}>
        <Logo />
        <div style={{ marginTop: 16 }}>Loading...</div>
      </div>
    </Styles.TooltipLoading>
  );

  const Tooltip = () => (
    <Styles.TooltipWrapper>
      <Styles.TooltipHeader>{selectedFunnelStep?.title || ''}</Styles.TooltipHeader>
      <Styles.TooltipContent>
        <div>
          <span>Gain / Loss</span>
          <Styles.TooltipValue color={validationDataTotal.gainLossTotal > 0 ? '#0ec76a' : '#ed3434'}>
            <Typography value={validationDataTotal.gainLossTotal}>
              {validationDataTotal.gainLossTotal > 0 ? '+' : ''}
              {((validationDataTotal.gainLossTotal || 0) * 100).toFixed(2)}%
            </Typography>
          </Styles.TooltipValue>
        </div>
      </Styles.TooltipContent>
      <Styles.TooltipButton
        onClick={() => {
          setQueryParams({
            activeTab: portfolioTabs.VALIDATION,
            dateFrom: new Date(activeOptimization?.start),
            dateTo: new Date(activeOptimization?.end),
          });
        }}
      >
        See detailed report
      </Styles.TooltipButton>
    </Styles.TooltipWrapper>
  );
  return (
    <div style={{ position: 'relative' }}>
      {validationTooltipVisible ? (
        loading ? (
          <Styles.ValidationTooltip offset={validationTooltipOffset}>
            <TooltipLoading />
          </Styles.ValidationTooltip>
        ) : (
          <Styles.ValidationTooltip offset={validationTooltipOffset}>
            <Tooltip />
          </Styles.ValidationTooltip>
        )
      ) : null}
      <Styles.ChartContainerStyled id={CHART_CONTAINER_ID} data-cy="portfolioPerformanceChart" />
    </div>
  );
}

export default PortfolioPerformanceChart;
