import { gql, MutationTuple, useMutation } from '@apollo/client';
import { NexoyaMutationUpsertPortfolioContentConfigArgs } from '../../types';

// GraphQL Mutation
const UPSERT_PORTFOLIO_CONTENT_CONFIG_MUTATION = gql`
  mutation upsertPortfolioContentConfig(
    $teamId: Int!
    $portfolioId: Int!
    $contentIds: [Float!]!
    $funnelStepMappings: [FunnelStepMapping!]!
    $impactGroupId: Float
  ) {
    upsertPortfolioContentConfig(
      teamId: $teamId
      portfolioId: $portfolioId
      contentIds: $contentIds
      funnelStepMappings: $funnelStepMappings
      impactGroupId: $impactGroupId
    )
  }
`;

// Hook with correct types
const useUpsertPortfolioContentConfigMutation = (): MutationTuple<
  any,
  NexoyaMutationUpsertPortfolioContentConfigArgs
> => {
  const [mutation, state] = useMutation<any, NexoyaMutationUpsertPortfolioContentConfigArgs>(
    UPSERT_PORTFOLIO_CONTENT_CONFIG_MUTATION,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  return [mutation, state];
};

export { UPSERT_PORTFOLIO_CONTENT_CONFIG_MUTATION, useUpsertPortfolioContentConfigMutation };
