import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { capitalize, toNumber } from 'lodash';
import { FunnelContainerStyled, FunnelStepsContainerStyled, LabelsContainerStyled } from '../Funnel/styles';
import { FunnelSteps } from '../Funnel/components/FunnelSteps';
import { FunnelData } from '../Funnel/MultiSeriesFunnel';
import { AssignMetricFunnelStepLabel } from './ContentSet/assignment/AssignMetricFunnelStepLabel';
import { NexoyaFunnelStepMapping, NexoyaFunnelStepV2, NexoyaMeasurement } from '../../../../types';
import { usePortfolio } from '../../../../context/PortfolioProvider';
import { TagStyled } from '../../styles/OptimizationProposal';
import { useMeasurementsQuery } from '../../../../graphql/measurement/queryMeasurements';
import { useTranslationsQuery } from '../../../../graphql/translation/queryTranslations';
import { useListConversionsQuery } from '../../../../graphql/contentSets/queryConversions';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../components-ui/Select';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components-ui/Popover';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../../../../components-ui/Command';
import translate from '../../../../utils/translate';
import { nexyColors } from '../../../../theme';
import RadioGroup from '../../../../components/RadioGroup';
import FormControlLabel from '../../../../components/FormControlLabel';
import Radio from '../../../../components/Radio';

const DEFAULT_COLOR = nexyColors.azure;
const INITIAL_DATA: FunnelData = {
  labels: [],
  subLabels: [],
  values: [],
  colors: [],
};

export type MappingType = 'assign_metric' | 'custom_conversions' | 'utm_tracking' | 'ignore_mapping';

export const ASSIGN_METRIC_OPTIONS = (customConversionsDisabled: boolean) => [
  { value: 'assign_metric', label: 'Assign metric', disabled: false },
  { value: 'custom_conversions', label: 'Custom conversions', disabled: customConversionsDisabled },
  { value: 'ignore_mapping', label: 'Ignore mapping', disabled: false },
  {
    value: 'utm_tracking',
    label: 'UTM tracking',
    disabled: true,
    endAdornment: () => (
      <TagStyled bgColor={nexyColors.lightBlue200} color={nexyColors.neutral900}>
        Coming soon
      </TagStyled>
    ),
  },
];

interface Props {
  funnelSteps: NexoyaFunnelStepV2[];
  providerId: number;
  accountId: number;
  assignedMetrics: (NexoyaFunnelStepMapping & { mappingType?: MappingType })[];
  setAssignedMetrics: Dispatch<SetStateAction<(NexoyaFunnelStepMapping & { mappingType?: MappingType })[]>>;
}

export const ContentMetricAssignment: React.FC<Props> = ({
  funnelSteps,
  providerId,
  accountId,
  assignedMetrics,
  setAssignedMetrics,
}) => {
  const [funnelData, setFunnelData] = useState<FunnelData>(INITIAL_DATA);
  const { selectedFunnelStep } = usePortfolio().selectedFunnelStep;
  const selectedFunnelStepId = selectedFunnelStep?.funnel_step_id;

  const { data: measurementData } = useMeasurementsQuery({ providerId });
  const { data: translationData } = useTranslationsQuery();

  const { data: listConversionsData } = useListConversionsQuery({
    providerId,
    adAccountContentId: accountId,
    skip: !accountId,
  });

  const translations = translationData?.translations || [];
  const conversions = listConversionsData?.listConversions;
  const measurements: Partial<NexoyaMeasurement[]> = measurementData?.measurements;
  const isCustomConversionsOptionDisabled = !accountId || !conversions?.length;

  useEffect(() => {
    setFunnelData(
      funnelSteps.reduce(
        (acc, step) => ({
          labels: [...acc.labels, step.title],
          subLabels: [...acc.subLabels, step.title],
          values: [...acc.values, [100]],
          colors: [...acc.colors, DEFAULT_COLOR],
        }),
        INITIAL_DATA,
      ),
    );
  }, [funnelSteps]);

  useEffect(() => {
    if (selectedFunnelStepId == null) return;

    setAssignedMetrics((prevState) => {
      if (prevState.some((metric) => metric.funnelStepId === selectedFunnelStepId)) return prevState;

      return [
        ...prevState,
        { funnelStepId: selectedFunnelStepId, metricId: null, conversionExternalIdentifierHash: null },
      ];
    });
  }, [selectedFunnelStepId, setAssignedMetrics]);

  const handleMetricTypeChange = (optionValue: MappingType) => {
    if (!selectedFunnelStepId) return;

    setAssignedMetrics((prevState) =>
      prevState.map((metric) =>
        metric.funnelStepId === selectedFunnelStepId
          ? {
              ...metric,
              mappingType: optionValue,
              ...(optionValue === 'ignore_mapping' ? { metricId: null, conversionExternalIdentifierHash: null } : {}),
            }
          : metric,
      ),
    );
  };

  const getSelectedMeasurementForFunnelStepId = (funnelStepId: number) =>
    measurements?.find(
      (m) => m.measurement_id === assignedMetrics.find((a) => a.funnelStepId === funnelStepId)?.metricId,
    );

  const renderAssignedMetricCombobox = () => (
    <Popover>
      <PopoverTrigger asChild>
        <div className="w-52 rounded-md border border-neutral-100 bg-white p-2 shadow-sm">
          <span className="block truncate">
            {translate(translations, getSelectedMeasurementForFunnelStepId(selectedFunnelStepId)?.name) ??
              'Select a metric'}
          </span>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <Command>
          <CommandInput placeholder="Search metrics..." />
          <CommandList>
            <CommandEmpty>No metrics found.</CommandEmpty>
            <CommandGroup>
              {measurements
                ?.filter((measurement) => measurement.optimization_target_type?.includes(selectedFunnelStep?.type))
                ?.map((measurement) => (
                  <CommandItem
                    key={measurement.measurement_id}
                    value={translate(translations, measurement.name)}
                    onSelect={() => {
                      setAssignedMetrics((prevState) =>
                        prevState.map((metric) =>
                          metric.funnelStepId === selectedFunnelStepId
                            ? { ...metric, metricId: toNumber(measurement.measurement_id) }
                            : metric,
                        ),
                      );
                    }}
                  >
                    <span>{translate(translations, measurement.name)}</span>
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );

  const renderInputsBasedOnMappingType = (mappingType?: MappingType) => {
    if (!mappingType) return null;

    if (mappingType === 'assign_metric') {
      return (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <div className="mt-3 text-lg text-neutral-900">Assign a metric</div>
            <div className="mt-0.5 text-sm font-light text-neutral-400">
              Assign a metric for the conversion goal selected above.
            </div>
          </div>
          {renderAssignedMetricCombobox()}
        </div>
      );
    }

    if (mappingType === 'custom_conversions') {
      return (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <div className="mt-3 text-lg text-neutral-700">Select conversion goal</div>
            <div className="mt-0.5 text-sm font-light text-neutral-400">
              Select a conversion goal as defined from this content set’s selected channel.
            </div>
          </div>
          <Select
            value={
              conversions
                ?.find(
                  (c) =>
                    c.externalIdentifierHash ===
                    assignedMetrics.find((m) => m.funnelStepId === selectedFunnelStepId)
                      ?.conversionExternalIdentifierHash,
                )
                ?.externalIdentifierHash?.toString() || ''
            }
            onValueChange={(externalIdentifierHash) => {
              setAssignedMetrics((prevState) =>
                prevState.map((metric) =>
                  metric.funnelStepId === selectedFunnelStepId
                    ? { ...metric, conversionExternalIdentifierHash: externalIdentifierHash }
                    : metric,
                ),
              );
            }}
          >
            <SelectTrigger className="w-52 border-neutral-100 bg-white p-2 shadow-sm">
              <SelectValue placeholder="Select a conversion goal" />
            </SelectTrigger>
            <SelectContent>
              {conversions?.map((conversion) => (
                <SelectItem
                  key={conversion.externalIdentifierHash}
                  value={conversion.externalIdentifierHash?.toString()}
                >
                  <span>{translate(translations, conversion.title)}</span>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <div className="flex flex-col gap-1">
            <div className="mt-3 text-lg text-neutral-900">Assign a metric</div>
            <div className="mt-0.5 text-sm font-light text-neutral-400">
              Assign a metric for the conversion goal selected above.
            </div>
          </div>
          {renderAssignedMetricCombobox()}
        </div>
      );
    }

    return null;
  };

  const selectedMappingType = assignedMetrics.find((m) => m.funnelStepId === selectedFunnelStepId)?.mappingType;

  return (
    <div className="ml-6 flex gap-10">
      <FunnelContainerStyled>
        <LabelsContainerStyled>
          <AssignMetricFunnelStepLabel
            assignedMetrics={assignedMetrics}
            getSelectedMeasurementForFunnelStepId={getSelectedMeasurementForFunnelStepId}
            funnelSteps={funnelSteps}
            translations={translations}
          />
        </LabelsContainerStyled>
        <FunnelStepsContainerStyled>
          <FunnelSteps withTooltip={false} funnelData={funnelData} funnelSteps={funnelSteps} />
        </FunnelStepsContainerStyled>
      </FunnelContainerStyled>
      <div className="flex flex-col gap-6">
        <div>
          <div className="mt-6 text-lg text-neutral-700">
            Mapping type for <span className="font-semibold">{capitalize(selectedFunnelStep?.title)}</span>
          </div>
          <div className="mt-2 text-sm font-light text-neutral-500">
            Assign metrics to funnel steps to track the performance of your marketing campaigns.
          </div>
          <RadioGroup className="ml-[-3px] mt-4 flex flex-col gap-1.5">
            {ASSIGN_METRIC_OPTIONS(isCustomConversionsOptionDisabled).map((option) => (
              <div key={option.value} className="flex items-center gap-2">
                <FormControlLabel
                  checked={option.value === selectedMappingType}
                  // @ts-ignore
                  onChange={() => !option.disabled && handleMetricTypeChange(option.value)}
                  value={option.value}
                  label={option.label}
                  control={<Radio />}
                  data-cy={option.value}
                  disabled={option.disabled}
                />
                {option.endAdornment && option.endAdornment()}
              </div>
            ))}
          </RadioGroup>
        </div>
        <div>{renderInputsBasedOnMappingType(selectedMappingType)}</div>
      </div>
    </div>
  );
};
