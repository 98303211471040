import { gql, useQuery } from '@apollo/client';
import { useTeam } from 'context/TeamProvider';

export const LIST_CONVERSIONS_QUERY = gql`
  query ListConversions($providerId: Float!, $adAccountContentId: Float!, $teamId: Float!) {
    listConversions(providerId: $providerId, adAccountContentId: $adAccountContentId, teamId: $teamId) {
      externalIdentifierHash
      providerId
      teamId
      title
    }
  }
`;

type ListConversionsQueryVariables = {
  providerId: number;
  adAccountContentId: number;
  teamId: number;
};

export function useListConversionsQuery({
  providerId,
  adAccountContentId,
  skip,
}: {
  providerId: number;
  adAccountContentId: number;
  skip?: boolean;
}) {
  const { teamId } = useTeam();

  const query = useQuery<
    {
      listConversions: {
        externalIdentifierHash: string;
        providerId: number;
        teamId: number;
        title: string;
      }[];
    },
    ListConversionsQueryVariables
  >(LIST_CONVERSIONS_QUERY, {
    variables: {
      providerId,
      adAccountContentId,
      teamId,
    },
    skip,
  });

  return query;
}
